module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"ashkanfekri","short_name":"ashkanfekri","start_url":"/","description":"Software engineer and open source creator. This is my digital garden.","background_color":"#5096ff","theme_color":"#5096ff","display":"fullscreen","icon":"./src/images/avatar-192_192.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a74a33bac8d88735e5017b1179ca48b3"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
